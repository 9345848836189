<template>
  <div class="food">
    <van-nav-bar
      :title="title || $t('food.title')"
      fixed
      placeholder
    >
      <template #left>
        <van-icon
          :name="$icon('back')"
          size="16"
          color="#000"
          is-link
          @click="$router.back()"
        />
      </template>
    </van-nav-bar>
    <hero
      :title="title"
      :image="categoryImage"
      :icon="foodIcon"
      :supports-favourite="true"
      :is-favourite="isFavourite"
      :is-editable="item.createdBy === userId"
      @mark-favourite="markFavourite"
    />
    <div class="food-nutritions">
      <div class="container">
        <data-table
          :data="productData"
          :title="nutritionalValuesTitle"
          class="food__data"
        />
        <a
          v-if="showShopLink"
          :href="shopLink"
          class="food__link"
          target="_blank"
          @click="clickShopLink"
        >
          <van-icon
            :name="$icon('external-link')"
            class="link__icon"
          />
          {{ $t('food.shopLink') }}
        </a>
        <infobox
          icon="info"
          :content="foodDisclaimer || $t('food.disclaimer')"
          class="food__notice"
        />
        <div class="actions">
          <van-button
            :icon="$icon('edit')"
            type="plain"
            class="food__action"
            :to="{
              name: 'calculator',
              params: {
                title: $t('food.edit'),
                food: { ...item, id },
              },
            }"
          >
            {{ isFoodGlobal() ? $t('food.editPortion') : $t('food.edit') }}
          </van-button>

          <template v-if="item.createdBy === userId">
            <van-button
              :icon="$icon('delete')"
              type="plain"
              class="food__action delete"
              @click="deleteFood"
            >
              {{ $t('food.delete') }}
            </van-button>
          </template>
        </div>
      </div>
      <div class="branding__container">
        <branding />
      </div>
    </div>
    <div class="food-form__container">
      <van-form
        validate-first
        class="food-form"
        @submit="addFood"
      >
        <van-collapse
          v-model="activeName"
          accordion
        >
          <van-collapse-item
            v-if="showDateSelect"
            name="1"
          >
            <template #title>
              <span>{{ $t('food.mealType') }}</span>
              <span>{{ $i18n.t(`food.mealTypes.${selectedMealType}`) }}</span>
            </template>
            <van-picker
              ref="mealTypePicker"
              show-toolbar
              :columns="mealTypeColumns"
              @confirm="selectMealType"
              @cancel="activeName = null"
            />
          </van-collapse-item>
          <van-collapse-item name="2">
            <template #title>
              <span>{{ $t('food.portion') }}</span>
              <span>{{ portionSizeLabel }}</span>
            </template>
            <van-picker
              ref="portionSizePicker"
              show-toolbar
              :columns="portionSizeColumns"
              @confirm="selectPortionSize"
              @change="changePortionSizeValues"
              @cancel="activeName = null"
            />
          </van-collapse-item>
          <van-collapse-item
            v-if="showDateSelect"
            name="3"
          >
            <template #title>
              <span>{{ $t('date') }}</span>
              <span>{{ dateLabel }}</span>
            </template>
            <calendar
              v-model="date"
              :date-selected="date"
              @date="updateDate"
            />
          </van-collapse-item>
        </van-collapse>
        <van-button
          native-type="submit"
          type="primary"
          block
          round
        >
          {{ $t(isMealScreen ? 'food.addToMeal' : editingDayplanEntry ? 'food.edit' : 'food.add') }}
        </van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog, Notify } from 'vant';
import { format, formatRelative } from 'date-fns';

import { auth, analytics, FieldValue } from '@/firebase';

import Food from '@/models/food';
import Diagnosis from '@/models/diagnosis';
import Dayplan from '@/models/dayplan';
import Calendar from '@/components/Calendar';
import DataTable from '@/components/DataTable';
import Branding from '@/components/Branding';
import Hero from '@/components/Hero';
import Infobox from '@/components/Infobox';

import {
  dateLocale,
  getNutritionUnit,
  checkAsmIndication,
  sanitizeCategoryName,
} from '@/helper';
import i18n from '../../../i18n';

// Generate an array of numbers between the start and end args.
const generateRange = (start, end, step = 1) => Array(Math.round(end / step))
  .fill()
  .map((_, idx) => (idx * step) + start);

export default {
  components: {
    Calendar,
    DataTable,
    Hero,
    Infobox,
    Branding,
  },
  props: {
    fromDate: { type: String, default: null },
    id: { type: String, default: null },
    mealType: { type: String, default: 'breakfast' },
    showDateSelect: { type: Boolean, default: true },
    showMealSelect: { type: Boolean, default: true },
  },
  data() {
    return {
      userId: auth.currentUser.uid,
      diagnosis: new Diagnosis(this.$store.getters['user/diagnosisName']),
      activeName: null,
      date: this.$store.getters['dayplan/selectedDate'],
      foodDisclaimer: null,
      title: '',
      image: '',
      item: {},
      nutritions: [],
      isFavourite: false,
      isEditable: false,
      editingDayplanEntry: false,
      portionSizeColumnData: [],
      valueIndexes: {},
      mealTypeColumns: [
        {
          text: this.$i18n.t('food.mealTypes.breakfast'),
          value: 'breakfast',
        },
        {
          text: this.$i18n.t('food.mealTypes.lunch'),
          value: 'lunch',
        },
        {
          text: this.$i18n.t('food.mealTypes.dinner'),
          value: 'dinner',
        },
        {
          text: this.$i18n.t('food.mealTypes.snack'),
          value: 'snack',
          selected: true,
        },
      ],
      portionAmount: 0,
      selectedQuantity: 1,
      selectedMealType: 'breakfast',
      portionSizeName: '',
      baseUnit: '',
      shopLink: 'https://loprofin.de/',
      showMealTypePicker: false,
      showPortionSizePicker: false,
      volume: 1,
      nutritionalValuesTitle: '',
      changedValue: false,
      showShopLink: false,
    };
  },
  computed: {
    isMealScreen() {
      return this.$route.name === 'addFoodToMeal';
    },
    categoryImage() {
      const category = this.categoryName;
      return (category === 'sonstiges') ? null : `/img/category-hero/${category}.jpg`;
    },
    categoryName() {
      if (this.isMealScreen) {
        return sanitizeCategoryName(this.item.category);
      }
      if (this.item.category && this.item.category.match(/Aminosäurenmischung/)) {
        const asmIndication = checkAsmIndication(this.item.title);
        if (asmIndication) {
          return `asm/${asmIndication}`;
        }
        return 'asm/generisch';
      }
      return sanitizeCategoryName(this.item.category?.trim()) || 'sonstiges';
    },
    dateLabel() {
      return formatRelative(this.date, Date.now(), {
        locale: dateLocale,
      });
    },
    foodIcon() {
      return (this.categoryName === 'sonstiges') ? 'food' : null;
    },
    portionSizeColumns() {
      const portionNames = Object.keys(this.portionSizeColumnData);
      const colValues = this.item.portionSizeName ? this.getColId() : 1;
      return [
        {
          values: portionNames,
          defaultIndex:
            this.selectedPortionSizeName ? portionNames.indexOf(this.selectedPortionSizeName) : 0,
        },
        {
          values:
            this.portionSizeColumnData[Object.keys(this.portionSizeColumnData)[this.getColId()]],
          defaultIndex: colValues
            ? this.selectedQuantity - 1 : (this.selectedQuantity * 2) - 1,
        },
      ];
    },
    portionSizeLabel() {
      let label = '';
      if (this.item.portionSizeName && this.portionSizeName === this.item.portionSizeName) {
        label = `${this.selectedQuantity} ${this.portionSizeName} (${this.getPortionSizeAmount()}${this.baseUnit})`;
      } else if (this.portionSizeName) {
        label = `${this.selectedQuantity} ${this.baseUnit}`;
      } else {
        label = this.$i18n.t('food.selectValue');
      }
      return label;
    },
    productData() {
      const requiredNutritions = Dayplan.getRelevantNutrients(this.item.nutritions);
      const nutritions = Object.entries(requiredNutritions).map((entry) => {
        const [label, value] = entry;
        return {
          label: this.$i18n.t(`nutritions.${label}`),
          value: typeof value === 'number'
            ? `${value.toLocaleString('de-DE', { maximumFractionDigits: 1 })} ${getNutritionUnit(label)}`
            : value,
        };
      });
      return nutritions.sort((a, b) => {
        const diagnosisLabel = this.diagnosis.getLabel(false);
        // Show relevant diagnosis nutrition value first.
        if (a.label === diagnosisLabel) {
          return -1;
        }
        if (b.label === diagnosisLabel) {
          return 1;
        }
        // Sort unspecified values last.
        if (a.value.trim() === 'k.A.') {
          return 1;
        }
        if (b.value.trim() === 'k.A.') {
          return -1;
        }
        return false;
      });
    },
  },

  created() {
    this.selectedMealType = this.mealType;
    const fromDate = this.$props.fromDate ?? format(this.date, 'yyyy-MM-dd');
    Dayplan.getFoodByDate(fromDate, this.id).get()
      .then((doc) => {
        if (doc.exists) {
          this.setupItemData(doc);
          this.editingDayplanEntry = doc.data().mealType === this.selectedMealType;
        } else {
          Food.get(this.id).get()
            .then((snapshot) => {
              this.setupItemData(snapshot);
              this.editingDayplanEntry = false;
            })
            .catch(err => console.error(err));
        }
      })
      .catch(err => console.error(err));
  },

  methods: {
    deleteFood() {
      Dialog.confirm({
        title: this.$i18n.t('food.dialog.title'),
        message: i18n.t('food.dialog.message'),
      })
        .then(() => {
          Food.delete(this.id).then(() => {
            Notify({
              type: 'primary',
              message: this.$i18n.t('food.deleted', [this.title]),
              duration: 5000,
              className: 'form-notification',
            });
            analytics.logEvent('food_delete', {
              item_id: this.id,
              item_name: this.title,
              item_category: this.foodType === 'meal' ? this.foodType : this.category,
              item_list_id: this.mealType,
            });
            this.$router.back();
          });
        });
    },
    setupItemData(item) {
      Food.isFavourite(this.id).then((data) => {
        this.isFavourite = data ? data.isFavourite : false;
      });
      this.item = item.data();

      if (this.item.mealType !== this.selectedMealType) {
        this.item.mealType = this.selectedMealType;
        this.item.id = this.id.replace(/^(breakfast|lunch|dinner|snack)-/, '');
      }

      Object.assign(this, this.item);
      if (this.selectedPortionSizeName) {
        this.portionSizeName = this.selectedPortionSizeName;
      }
      if (!this.item.portionSizeName && !this.item.portionAmount) {
        this.portionSizeName = this.item.baseUnit;
        this.selectedQuantity = 100;
      }
      if (this.selectedPortionAmount) {
        this.selectedQuantity = this.selectedPortionAmount;
        if (this.item.portionSizeName === this.item.selectedPortionSizeName) {
          this.selectedQuantity /= this.item.portionAmount;
        }
      }
      this.nutritionalValuesTitle = `${this.$i18n.t('food.nutritionalValues')} / ${this.item.baseAmount}${this.item.baseUnit}`;
      this.portionSizeColumnData = {};
      if (this.item.portionSizeName) {
        this.portionSizeColumnData[this.item.portionSizeName] = generateRange(0.5, 10, 0.5);
        // this sets the index of 'package' to selectedQuantity, if its portionSizeName is
        // what the value was originally saved in; else sets the value to default '1'
        this.valueIndexes[this.item.portionSizeName] = this.portionSizeName
          === this.item.portionSizeName ? this.selectedQuantity : 1;
      }
      this.portionSizeColumnData[this.$i18n.t(`units.${this.item.baseUnit}`)] = generateRange(1, 500);
      this.valueIndexes[this.$i18n.t(`units.${this.baseUnit}`)] = this.portionSizeName
        === this.$i18n.t(`units.${this.baseUnit}`) ? this.selectedQuantity : this.baseAmount || 100;
      this.isLoprofinProduct();
    },
    getPortionSizeAmount() {
      let amount = this.selectedQuantity;
      if (this.portionSizeName === this.item.portionSizeName) {
        amount *= this.portionAmount;
      }
      return amount;
    },
    markFavourite(value) {
      this.isFavourite = value;
      Food.markFavourite(this.foodId ?? this.id, value);
      analytics.logEvent(value ? 'favorite_add_food' : 'favorite_remove_food', {
        item_id: this.foodId ?? this.id,
        item_name: this.title,
        item_category: this.foodType === 'meal' ? this.foodType : this.category,
        item_list_id: this.mealType,
      });
    },
    calculateSelectedPortionAmount() {
      let amount = this.selectedQuantity;
      if (this.item.portionSizeName !== this.portionSizeName) {
        amount = this.selectedQuantity;
      } else {
        amount = this.selectedQuantity * this.item.portionAmount;
      }
      return amount;
    },
    addFood() {
      // Set picker data if user clicks button without confirming the picker.
      if (this.$refs.portionSizePicker) {
        this.$refs.portionSizePicker.confirm();
      }
      if (this.$refs.mealTypePicker) {
        this.$refs.mealTypePicker.confirm();
      }

      this.item.nutritionsCalculated = Food.calculateNutritionValues(
        this.item, this.getPortionSizeAmount(),
      );
      const item = {
        ...this.item,
        id: this.id,
        isFavourite: this.isFavourite,
        selectedPortionAmount: this.calculateSelectedPortionAmount(),
        selectedPortionSizeName: this.portionSizeName,
        mealType: this.selectedMealType,
        mealTypes: FieldValue.arrayUnion(this.selectedMealType),
      };
      if (this.isMealScreen) {
        const mealId = this.$store.getters['meal/mealId'];
        this.$store.commit('meal/addFoodItem', item);
        if (mealId) {
          analytics.logEvent('meal_add_food', {
            item_id: item.id,
            item_name: item.title,
            item_category: item.foodType === 'meal' ? item.foodType : item.category,
            item_list_id: this.mealType,
          });
          this.$router.back();
          return;
        }
        this.$router.push({
          name: 'MealDetails',
          params: {
            mealType: this.mealType,
          },
        });
        return;
      }
      Dayplan.addFood(item).then(() => {
        Notify({
          type: 'success',
          message: this.$i18n.t('food.success'),
          className: 'form-notification',
        });
        analytics.logEvent(item.foodType === 'meal' ? 'day_add_meal' : (this.editingDayplanEntry ? 'day_edit_food' : 'day_add_food'), {
          item_id: item.id,
          item_name: item.title,
          item_category: item.foodType === 'meal' ? item.foodType : item.category,
          item_list_id: this.mealType,
        });
      }).catch(() => {
        Notify({
          type: 'danger',
          message: this.$i18n.t('food.fail'),
          className: 'form-notification',
        });
      });
    },
    selectMealType(option) {
      this.selectedMealType = option.value;
      this.activeName = null;
    },
    selectPortionSize(value) {
      const [portionSizeName, amount] = value;
      this.portionSizeName = portionSizeName;
      this.selectedQuantity = amount;
      this.activeName = null;
      this.item.selectedUnit = portionSizeName;
      this.item.selectedPortionSizeName = portionSizeName;
    },
    changePortionSizeValues(picker, values, index) {
      const [name, selectedValue] = values;
      picker.setColumnValues(1, this.portionSizeColumnData[name]);
      const valueIndex = this.valueIndexes[name];
      const baseUnitLabel = this.$i18n.t(`units.${this.item.baseUnit}`);
      // when switching the portion labels, set the portion value to the indexOf the saved value
      if (index === 0) {
        picker.setColumnIndex(1, this.portionSizeColumnData[name].indexOf(valueIndex));
      }
      // when switching portion values, set the column index and update the saved indexes
      if (index === 1) {
        picker.setColumnIndex(1, this.portionSizeColumnData[name].indexOf(selectedValue));
        if (this.item.portionSizeName === name) {
          this.valueIndexes[name] = selectedValue;
        } else {
          this.valueIndexes[baseUnitLabel] = selectedValue;
        }
      }
    },
    updateDate(value) {
      this.date = value;
      this.activeName = null;
      this.showDatePicker = false;
      this.$store.commit('dayplan/selectDate', this.date);
    },
    getColId() {
      let colId = 0;
      if (this.item.portionSizeName && this.item.selectedPortionSizeName) {
        colId = this.item.selectedPortionSizeName === this.item.portionSizeName ? 0 : 1;
      }
      return colId;
    },
    isLoprofinProduct() {
      if (/loprofin|milupa lp/.test(this.item?.lowerTitle)) {
        this.showShopLink = true;
      }
    },
    isFoodGlobal() {
      if (this.item?.createdBy !== this.userId || this.item?.globalId) {
        return true;
      }
      return false;
    },
    clickShopLink(e) {
      analytics.logEvent('outbound_link_click', {
        link_text: e.target.text,
        link_url: e.target.href,
        item_id: this.item.id,
        item_name: this.item.title,
        item_category: this.item.category,
        item_list_id: this.mealType,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;
@use '~@/styles/icons' as icons;

.food {
  min-height: calc(100vh - 70px);
}
.food__link {
  margin-bottom: config.$spacing-xxl;
  width: 100%;
  display: block;
  text-align: center;
  color: #000;
  font-weight: 600;

  .van-icon {
    margin-right: 0.5rem;
    font-size: 1.3rem;
    vertical-align: bottom;
  }
}

.food__data {
  margin: config.$spacing 0;
}

.food__notice {
  margin-top: config.$spacing-xxl;
}

.food-form {
  position: sticky;
  z-index: 20;
  bottom: 65px; // Ensure not partially hidden by tabbar.
  padding: config.$spacing-sm config.$spacing;
  box-shadow: 0 -5px 10px -10px rgba(121, 121, 121, 0.25);
  background-color: config.$color-lighter;

  .van-collapse {
    margin-bottom: 0.625rem;

    &:after {
      display: none;
    }
  }

  .van-collapse-item + .van-collapse-item {
    border-top: 1px solid #b9b9b9;
  }

  .van-collapse-item__content {
    padding: 0;
  }

  .van-cell {
    padding: 0.375rem 0.75rem;
    background-color: transparent;
  }
}

.food-form__input {
  border: solid 1px #e7e7e7;
  border-radius: 5px;
  margin-bottom: config.$spacing-xs;
  background-color: #fff;
  color: #242526;
}
.food-form__container {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: config.$max-width-lg;
  margin: auto;
}

.food-nutritions {
  padding-bottom: 220px; // workaround for the food-form to be sticky at the bottom
}

.link__icon {
  img {
    width: 15px;
    height: 14px;
    position: relative;
    bottom: 4px;
  }
}

.actions {
  margin-top: 8px;
  .food__action {
    border: none;
    font-size: config.$font-size-sm;
    display: block;
    &.delete {
      color: config.$color-error;
    }
    img {
      width: 15px;
      height: 16px;
      position: relative;
      bottom: 3px;
    }
    .van-button__text {
      margin-left: 7px;
    }
  }
}

</style>
<style>
  .van-cell__title {
    display: flex;
    justify-content: space-between;
  }
  .van-cell__right-icon {
    display: none;
  }
</style>
