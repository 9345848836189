<template>
  <div
    class="hero"
    :class="{ 'has-cover-image': image }"
  >
    <div class="hero__inner">
      <h1
        class="hero__title"
      >
        <van-field
          v-if="editing"
          ref="titleInput"
          v-model="customTitle"
          @input="$emit('custom-title', customTitle)"
          @keyup.13="editing = !editing;"
        />
        <span
          v-if="!editing"
          class="hero__label"
        >
          {{ customTitle || title }}
        </span>
        <van-icon
          v-if="isEditable"
          name="edit"
          @click="editing = !editing; setFocus();"
        />
      </h1>
      <div class="hero__favourite">
        <van-icon
          v-if="supportsFavourite"
          :name="$icon(isFavourite ? 'star-active' : 'star')"
          class="hero__icon--favourite"
          @click="$emit('mark-favourite', !isFavourite)"
        />
      </div>
    </div>
    <van-icon
      v-if="icon"
      :name="$icon(icon)"
      class="hero__icon--type"
    />
    <img
      v-if="image"
      class="hero__cover"
      :src="image"
    >
    <img
      v-if="heroBgIcon"
      class="hero__bg--icon"
      :src="heroBgIcon"
    >
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    supportsFavourite: { type: Boolean, default: false },
    isFavourite: { type: Boolean, default: false },
    icon: { type: String, default: '' },
    image: { type: String, default: '' },
    isEditable: { type: Boolean, default: false },
    heroBgIcon: { type: String, default: '' },
  },
  data() {
    return {
      customTitle: this.title,
      editing: false,
    };
  },
  created() {
    this.customTitle = this.title;
  },
  methods: {
    setFocus() {
      // Some delay is necessary to avoid this function to fire before
      // the DOM has been updated
      setTimeout(() => this.$refs?.titleInput?.$refs?.input?.focus(), 200);
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '~@/styles/config' as config;

.hero {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: clamp(
    10.625rem,
    #{math.div(170, 320) * 100}vw,
    21.25rem
  ); // Keep 320x170 aspect ratio.
  overflow: hidden;
  mix-blend-mode: multiply;
  background-image: linear-gradient(
    239deg,
    rgba(124, 83, 204, 1) 0%,
    rgba(84, 55, 140, 1) 61%,
    rgba(58, 36, 99, 1) 100%
  );
  background-color: #f5f5f5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 40%
    );
    content: "";
  }

  .van-icon {
    vertical-align: middle;
  }
}

.hero__icon {
  cursor: pointer;

  &--favourite {
    font-size: clamp(1.5rem, 7vw, 2.125rem);
  }

  &--type {
    position: absolute;
    bottom: -0.342em;
    right: -0.083em;
    font-size: 10.875rem;
  }
}

.hero__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero__inner {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.875rem;
  width: 100%;
  padding: 0 config.$spacing;
}

.hero__title {
  display: flex;
  align-items: center;
  max-width: 70%;
  margin: 0;
  font-size: clamp(config.$font-size-lg, 5vw, config.$font-size-xl);
  font-weight: bold;
  line-height: 1.19;
  color: #fff;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  .van-cell {
    padding: 0;
    background-color: transparent;
  }

  .van-icon {
    font-size: 30px;
    margin-left: config.$spacing-xxs;
  }

  ::v-deep .van-field__control {
    font-size: clamp(config.$font-size-lg, 5vw, config.$font-size-xl);
    font-weight: bold;
    color: #fff;
    height: 38px;
  }
}

.hero__label {
  padding-top: 5px;
}

.hero__bg--icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 138px;
  height: 106px;
}

</style>
