<template>
  <div
    class="infobox"
    :class="{
      'infobox--dark': dark,
      'infobox--outline': outline,
    }"
  >
    <van-icon
      v-if="icon"
      :name="icon"
      :size="24"
      class="infobox__icon"
    />
    <div class="infobox__content">
      <div v-if="content">
        {{ content }}
      </div>
      <slot v-else />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: { type: String, default: null },
    dark: { type: Boolean, default: false },
    icon: { type: String, default: null },
    outline: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.infobox {
  position: relative;
  padding: 16px 12px;
  border: 1px solid config.$color-light;
  border-radius: 3px;
  margin-top: 12px;
  background-color: config.$color-light;
  color: config.$color-dark;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.38;

  &--outline {
    border-color: config.$brand-primary;
    background-color: #fff;

    &.infobox--dark {
      background-color: transparent;
      color: #fff;
    }
  }
}

.infobox__icon {
  position: absolute;
  top: -12px;
  left: 32px;
  color: config.$brand-primary;
}
</style>
